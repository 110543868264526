import React, { Component } from 'react';
//import { Button, Card, Row, Col, Image, CardTitle } from 'react-materialize';
import moment from 'moment';
import { Pie } from 'react-chartjs-2';

//Paginas

import apiUrl from '../../../axiosApi';
import './index.css';


//Imagens 
import iconInstagramImg from '../../../assets/instagramicon01.png';
import iconYoutubeImg from '../../../assets/youtubeicon01.png';

class Home extends Component{
      constructor(props){
        super(props)
        this.state = {
        }
      }

      

      render(){
        return(
            <footer>
              <div className="copy">
                © 2022 Quattro Edtech. Todos os direitos reservados. 
              </div>
              <div className="linha">
                <div className="redesSociais">
                  <a rel="noopener noreferrer" target="#" href="https://www.instagram.com/quattrofintech/"> <img src={iconInstagramImg} alt="" /></a>
                  <a rel="noopener noreferrer" target="#" href="https://www.youtube.com/channel/UC6OBlD9wvUxn9K-f5UWHN1w"> <img src={iconYoutubeImg} alt="" /></a>
                </div>
                <div className="linkPrivacidade">
                  {/*<a href="/quemsomos">Quem Somos</a>*/}
                  <a rel="" href="/politicadeprivacidade"  target='_blank'>Política de Privacidade</a>
                  <a rel="" href="/termosdeuso" target='_blank'>Termos de Uso</a>                  
                  <a href="/suporte" target='_blank'>Ajuda</a>
                </div>
              </div>
            </footer>    
      );
    }
}
export default Home;