import React, { Component } from 'react';

//Paginas
import apiUrl from '../../axiosApi';
import './index.css';

import Menu from '../template/menu.component';
import Footer from '../template/footer.component';
// img 



class Suporte extends Component{
    constructor(props){
      super(props)
      this.state = {
        carregou:'0',
        msg:'',
        id: localStorage.getItem('idUsuario'),
        nome: '',
        email: '',
        telefone: '',
        mensagem: '',
        lista: [],
      }
      sessionStorage.removeItem('tituloPagina');
      sessionStorage.setItem('tituloPagina', 'SUPORTE');
      this.getHttp();
    }

    changeHandler = e => {
      this.setState({[e.target.name]: e.target.value})
    }

    getHttp = () =>{
      const {id} = this.state;

      apiUrl.get(`/usuario/${id}/`).then( res => {
        console.log(res.data);
        //Dados Pessoais
        this.setState({"id": res.data.id});
        this.setState({"nome": res.data.nome});
        this.setState({"email": res.data.dadosCadastro.email});
        this.setState({"telefone": res.data.dadosCadastro.telefone || ""});            

           //Carrega Dados
        this.setState({"carregou": "1"});
        this.setState({"mensagem": ""});

        })
        .catch( error => {
            console.log(error);
            this.toastMensagem("Erro ao trazer dados do usuário, faça login novamente. Error: " + error);
            this.setState({"mensagem": "Erro ao carregar os Dados Pessoais. Faça login Novamente."});
        });
    }
    listarTodosHttp = async e => {
      e.preventDefault();
      await apiUrl.get('/users/')
      .then(res => {
          this.setState({ lista: res.data});
        
      })
      .catch(error => {
        alert(error);
      })
    }
    cadastrarPergunta = async e => {      
      this.setState({"msg": ""});
      e.preventDefault();
      this.limpaCampos();
      this.toastMensagem('Pagina em mantenção... Entre em contato pelo WhatsApp');
      const {nome, email, telefone, mensagem} = this.state;
      /*await apiUrl.post('/suporte', {
        nome: nome,
        email: email,
        telefone: telefone,
        mensagem: mensagem})
      .then(res => {
        //Resposta do Servidor
        this.setState({"msg": "Pergunta Realizada. Logo entraremos em contato"});
        this.toastMensagem("Pergunta Realizada. Logo entraremos em contato")
        console.log(res.data);
        this.limpaCampos();
      })
      .catch(error => {
        this.setState({"msg": "Erro ao Realizar Pedido de Suporte"});  
        this.toastMensagem("Erro ao Realizar Pedido de Suporte" + error);        
        this.limpaCampos()
      })*/
    }
    limpaCampos() {
        this.setState({"mensagem": ""})
    }
    toastMensagem(message){
      const M = window.M;
      M.toast({html: message, classes: 'rounded'})
    }
    
    render(){
      const {nome, email, telefone, mensagem} = this.state;
      return( 
          
          <div className="suporte">
            <Menu />
            <div className="row">
              <div className="col s10 push-s1 m10 push-m1 l10 push-l1">      
                <blockquote>
                  <p >Conte com nosso suporte para tirar suas dúvidas e resolver as questões que surgirem. Preencha os campos abaixo que em até 2 dias úteis a nossa equipe irá lhe retornar o contato.</p>
                  <a href={"https://wa.me/5521991731632?text=Email:%20"+email+"%20%20%20%20%20\nNome:%20"+nome+"%20%20%20%20%20%20%20%20\nMensagem:%20Preciso%20de%20Ajuda."} target='_blank'>WHATSAPP</a>
                </blockquote>
              </div>
            </div>
            <div className="row">
              <form className="col s10 push-s1 m8 push-m2 l6 push-l3"
                onSubmit={this.cadastrarPergunta}>
                {/*<div className="row">
                  <div className="input-field col s10 push-s1 m8 push-m2 l6 push-l3">
                    <input id="nome" type="text" className="validate" required
                      name="nome" value= {nome} onChange={this.changeHandler}/>
                    <label htmlFor="nome">Nome Completo</label>
                  </div>
                </div>
                <div className="row">
                  <div className="input-field col s10 push-s1 m8 push-m2 l6 push-l3">
                    <input id="email" type="email" className="validate" required
                      name="email" value= {email} onChange={this.changeHandler}/>
                    <label htmlFor="email">Email</label>
                  </div>
                </div>
                <div className="row">
                  <div className="input-field col s10 push-s1 m8 push-m2 l6 push-l3">
                    <input id="telefone" type="text" className="validate" required
                      name="telefone" value= {telefone} onChange={this.changeHandler}/>
                    <label htmlFor="telefone">Telefone</label>
                  </div>
                </div>*/}
                <div className="row">
                  <div className="input-field col s10 push-s1 ">
                    <textarea id="mensagem" className="validate" required
                      name="mensagem" value= {mensagem} onChange={this.changeHandler}></textarea>
                    <label htmlFor="mensagem">Mensagem</label>
                  </div>
                </div>
                <div className="row">
                  <div className="col s12 m12 l12">
                    <button className="btn waves-effect waves-light col s10 push-s1 m10 push-m1 l6 push-l3" type="submit" name="action">PERGUNTAR</button>
                  </div>
                </div>
                </form>
              </div>
              <Footer />
          </div>
    );
}
};
export default Suporte;