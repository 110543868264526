import React, { Component } from 'react';


//Paginas
import M from  'materialize-css/dist/js/materialize.min.js';
//import Footer from './template/footer'
import Router from './router'
import './App.css'

class App extends Component{

  constructor(props) {
    super(props);
    this.state = {
      id: "",
    }
  }

  render(){
    return (
      <div className="App">
            <Router />       
      </div>
    );
  };

};

export default App;
