import React, { Component } from 'react';
import {BrowserRouter, Switch, Route} from 'react-router-dom';

//EDTECH
import IndexEdtech from './component/index.component';
import HomeEdtech from './component/home.component';
import CursoEdtech from './component/curso.component';
import LoginEdtech from './component/login.component';
import PerfilEdtech from './component/perfil.component';
import LogoutEdtech from './component/logout.component';
import LojaEdtech from './component/plano.component';
import TermosdeUso from './component/termo.component';
import PoliticadePrivacidade from './component/politica.component';
import Suporte from './component/suporte.component';
import Status from './component/status.component';

class Routes extends Component{
    constructor(props){
        super(props)
        this.state = {
          carregou:'0',
          mensagem:'Seja bem vindo. Temos novas Ofertas para você!',
          status: localStorage.getItem('idStatus'),
          id: localStorage.getItem('idAluno'),
          token: localStorage.getItem('X-Cookie-Status')
        }
      } 
      
      render(){
            if ( localStorage.getItem('idUsuario') !== '0' && localStorage.getItem('idUsuario') !== null
            && localStorage.getItem('idUsuario') !== "" && localStorage.getItem('idUsuario') !== "null" && this.state.token && localStorage.getItem('logado') !== false) {
        return(  
            localStorage.getItem("idStatus") === "506"
                ?
                    ""
                :
                    <BrowserRouter>
                        <Switch>
                            <Route path="/home"  component={HomeEdtech} />  
                            <Route path="/curso/:idCurso"  component={CursoEdtech} />                 
                            <Route path="/perfil"  component={PerfilEdtech} />   
                            <Route path="/loja"  component={LojaEdtech} />                                        
                            <Route path="/politicadeprivacidade"  component={PoliticadePrivacidade} />                                        
                            <Route path="/termosdeuso"  component={TermosdeUso} />                                        
                            <Route path="/suporte"  component={Suporte} />                                        
                            <Route path="/login"  component={LoginEdtech} />                                        
                            <Route path="/logout"  component={LogoutEdtech} />
                            <Route path="/status"  component={Status} />
                            <Route path="/"  component={IndexEdtech} />     
                            <Route component={"/"} />                                     
                        </Switch>
                    </BrowserRouter>
          );
        } else {
            return(
                <BrowserRouter>
                        <Switch>
                            <Route path="/login"  component={LoginEdtech} />  
                            <Route path="/status"  component={Status} />  
                            <Route path="/"  component={IndexEdtech} />   
                            <Route component={LoginEdtech} />                      
                        </Switch>
                </BrowserRouter>
            );
        }
       /* {
            if ( localStorage.getItem('idUsuario') !== '0' && localStorage.getItem('idUsuario') != null
                    && localStorage.getItem('idUsuario') !== "" && localStorage.getItem('idUsuario') !== "null" && this.state.token) {
                return(  
                    localStorage.getItem("idStatus") === "506"
                    ?
                    <BrowserRouter>
                        <MenuAdm/>
                        <Switch>
                            <Route path="/admin/paineldecontrole"  component={HomeAdm} />
                            <Route path="/admin/aluno"  component={EmpAluno} />
                            <Route path="/admin/aporte"  component={EmpAporte} />
                            <Route path="/admin/conteudo"  component={EmpConteudo} />
                            <Route path="/admin/relatorio"  component={EmpRelatorio} />
                            <Route path="/admin/empresa"  component={Empresa} />
                            <Route path="/admin/aulas"  component={AulasAdm} />
                            <Route path="/admin/cursos"  component={CursosAdm} />
                            <Route path="/admin/produto"  component={ProdutoAdmin} />
                            <Route path="/carteiraquattro"  component={CarteiraQuattro} />

                            <Route path="/admin/debug"  component={Debug} />
                            
                            <Route exact path="/logout"  component={Logout} />                    
                            <Route component={NotFound} />
                        </Switch>
                    </BrowserRouter>  
                    :          
                    <BrowserRouter>
                        <Menu/>
                        <Switch>
                            <Route exact path="/"  component={Home} />
                            <Route exact path="/manutencao"  component={Manutencao} />
                            <Route path="/aporte"  component={Aporte} />
                            <Route path="/suporte"  component={Suporte} />
                            <Route path="/perfil"  component={Perfil} />
                            <Route path="/carteira"  component={Carteira} />
                            <Route path="/informativo"  component={Informativo} />
                            <Route path="/politicadeprivacidade"  component={Politica} />
                            <Route path="/produtos"  component={Loja} />
                            <Route path="/quemsomos"  component={QuemSomos} />
                            <Route path="/termosdeuso"  component={Termo} />
                            <Route path="/indiqueamigos"  component={Indique} />
                            <Route path="/cursos/:idCurso"  component={Aula} />
                            <Route path="/produto/:idProduto"  component={Produto} />
                            <Route exact path="/cursos"  component={Cursos} />
                            <Route exact path="/aovivo"  component={AoVivo} />
                            
                            <Route path="/conteudoaberto"  component={Aberto} />
                            <Route exact path="/logout"  component={Logout} />
                            
                            <Route path="/login"  component={Login} />
                            <Route path="/cadastro"  component={Cadastro} />
                            <Route path="/esqueciasenha"  component={EsqueciSenha} />
                                            
                            <Route component={NotFound} />
                        </Switch>
                        <Footer/> 
                    </BrowserRouter>
                );
            } else {
                return(
                    <BrowserRouter>
                            <Switch>
                                <Route path="/login"  component={Login} />  
                                <Route path="/cadastro"  component={Cadastro} />  
                                <Route path="/esqueciasenha"  component={EsqueciSenha} />  
                                <Route component={Login} />                      
                            </Switch>
                    </BrowserRouter>
                );
            }
    
            /*
            return( 
                <BrowserRouter>
                    <Switch>
                        <Route exact path="/"  component={Manutencao} />
                        <Route exact path="/manutencao"  component={Manutencao} />    
                        <Route component={Manutencao} />
                    </Switch>
                </BrowserRouter>
            );
            
        }*/
    }
}
export default Routes;