import React, { Component } from 'react';

//Paginas
//import apiUrl from '../../axiosApi';
import './index.css';

/* img */



class Informativo extends Component{
    constructor(props){
      super(props)
      this.state = {        
        carregou:'0',
        mensagem:'',
        id: '',
        termo: '',
        status: '',
      }
      sessionStorage.removeItem('tituloPagina');
      sessionStorage.setItem('tituloPagina', 'TERMOS DE USO');
    }

    changeHandler = e => {
      this.setState({[e.target.name]: e.target.value})
    }

    
    render(){
      return( 
        <div className="termo">
          <div className="row">
            <div className="responsive-container col s12 m12 l12">
              <h2 className="flow-text center" >Termos e Condições de Uso</h2>
            </div>  
          </div> 
          <div className="row">
            <a target="_blank" rel="noopener noreferrer" href="https://files.investquattro.com.br/termos-e-condicoes/TermosECondicoesDeUso.pdf">BAIXAR</a>
            <div className="responsive-container col s12 m12 l12">
              <iframe src="https://files.investquattro.com.br/termos-e-condicoes/TermosECondicoesDeUso.pdf" frameBorder="0" allowFullScreen></iframe>
            </div>  
          </div>
              
        </div>
    );
}
}
export default Informativo;