import React, { Component } from 'react';
//import { Button, Card, Row, Col, Image, CardTitle } from 'react-materialize';
import moment from 'moment';
import { Pie } from 'react-chartjs-2';

//Paginas

import apiUrl from '../../../axiosApi';
import './index.css';

//Imagens 
import logoImg from '../../../assets/logoteste.png';


class Home extends Component{
      constructor(props){
        super(props)
        this.state = {
          blackScroll: true,
          //notiicacao: false,
          notiicacao: localStorage.getItem('notiicao'),
        }
        this.inicializaNotiicacao();
      }

      componentDidMount(){
        const M = window.M;
        document.addEventListener('DOMContentLoaded', function() {
          var elems = document.querySelectorAll('.sidenav');
          var instances = M.Sidenav.init(elems, {});
        });
        
        document.addEventListener('DOMContentLoaded', function() {
          var elems = document.querySelectorAll('.dropdown-trigger');
          var instances = M.Dropdown.init(elems, 
            {
              alignment: "center",
              hover: false, 
              inDuration: 10,
              coverTrigger: false,
            });
            }
        );

        const scrollListener = () =>{
          if(window.scrollY > 70){
            this.setState({blackScroll: true});
            document.getElementById('menu').style.background = '#141414';
          }else{
            this.setState({blackScroll: false});
            document.getElementById('menu').style.background = 'none';
          }
        }

        window.addEventListener('scroll', scrollListener);

        return() =>{
          window.removeEventListener('scroll', scrollListener);
        }
      }
      
      inicializaNotiicacao = () =>{
        if(localStorage.getItem('notiicao') != true || localStorage.getItem('notiicao') != false){
          localStorage.setItem('notiicao', false);
          this.setState({
            notiicacao: false,
          })
        }
      }

      alteranotiicacao = () =>{
        localStorage.setItem('notiicao', true);
        this.setState({
          "notiicacao": true,
        })
          
      }

      render(){
        const {blackScroll, notiicacao} = this.state;      
        
        return(
            <header id="menu">
              <div className="headerLogo">
              <a href="#" data-target="slide-out" className="sidenav-trigger">&#9776;</a>
                <a href="/home">
                  <img src={logoImg} className="responsive-img"/>
                </a>
              </div>
              <div className="headerUser"> 
                <ul className="right hide-on-med-and-down">
                    {/*<li><a className="dropdown-trigger" href="#!" data-target="notificacoes" onClick={this.alteranotiicacao}>
                    <i className="material-icons" id={notiicacao ? "naoNotiica":"notiica"}>notifications_none</i></a></li>*/}
                    <li><a className="dropdown-trigger" href="#!" data-target="aluno">Aluno</a></li>
                </ul>
              </div>
              <ul id="slide-out" className="sidenav">
                    <li><a className="menuSidenav" href="/home">Home</a></li>
                    <li><a className="menuSidenav" href="/perfil">Perfil</a></li>
                    <li><a className="menuSidenav" href="/loja">Loja</a></li>
                    <li><a className="menuSidenav" href="/logout">Sair</a></li>
                </ul>
              <ul id="aluno" className="dropdown-content">
                    <li><a href="/perfil">Perfil</a></li>
                    <li><a href="/loja">Loja</a></li>
                    <li><a href="/logout">Sair</a></li>
              </ul>    
              <ul id="notificacoes" className="dropdown-content">
                    <li>Não há notificações testando um texto bem grande pra ver como fica a informação deste artigo</li>
                    <li>Seja bem vindo a Quattro Edtech</li>
                    <li>Seja bem vindo a Quattro Edtech</li>
                    <li>Seja bem vindo a Quattro Edtech</li>
              </ul>
            </header>
      );
    }
}
export default Home;