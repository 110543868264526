import React, { Component } from 'react';
//import { Button, Card, Row, Col, Image, CardTitle } from 'react-materialize';
import moment from 'moment';
import { Pie } from 'react-chartjs-2';

//Paginas

import apiUrl from '../../axiosApi';
import Menu from '../template/menu.component/index';
import Footer from '../template/footer.component/index'
import './index.css';

//Imagens

function nl2br(texto) {
  return texto.replace(new RegExp('\r?\n','g'), '<br />');
}

class Curso extends Component{
      constructor(props){
        super(props)
        this.state = {
          carregou:'0',
          mensagem:'',
          scrollXX: 0,
          idCursoAluno: 0,
          idCurso: props.match.params.idCurso,
          id:localStorage.getItem('idUsuario'),
          nome: "",
          texto: "",  
          temas: "",
          porcentagem: 0,
          aulas: [],
          loading: true,
          aulaAtual: 0,
          indiceAulaAtual: 0,
          progresso: [],
          listaTemas: [],
          curso: [],
          qntAulasAps: 0,
          linkVideo: "https://www.youtube.com/embed/Qmhqd9ND4uw",
          listarLive: [],
      }
        
        this.getHttp(); 
        this.verificaSeJaAceitouContrato();
      }

      getHttp = () =>{
        const id = localStorage.getItem('idUsuario');
  
        apiUrl.get(`/usuario/${id}/`).then( res => {
          //Dados Pessoais
          this.setState({"id": res.data.id});
          this.setState({"nome": res.data.nome});
          this.setState({"cpf": res.data.cpf});
          this.setState({"email": res.data.dadosCadastro.email});
          this.setState({"telefone": res.data.dadosCadastro.telefone || ""});
          this.setState({"dtCadastro": res.data.dadosCadastro.dataCadastro});
          this.setState({"dtNascimento": res.data.dadosCadastro.dataNascimento || ""});
          this.setState({"sexo": res.data.dadosCadastro.sexo || ""});
          this.setState({"status": "2"});
          this.setState({"icon": ""});                
  
             //Carrega Dados
          this.setState({"carregou": "1"});
          this.setState({"mensagem": ""});

          this.listarCurso();
          this.listarAulas(res.data.id);
          this.listarLive();
  
          })
          .catch( error => {
              console.log(error);
              this.toastMensagem("Erro ao trazer dados do usuário, faça login novamente. Error: " + error);
          });
      } 

      verificaSeJaAceitouContrato = () =>{
        if(this.state.idCurso > 2){
          apiUrl.get(`/usuario-curso/modelo/${this.state.id}`)
        .then( res => {
          if(res.data.modeloCurso.id == 1){
            alert('Aceite o Contrato do curso Prime na loja.');
            window.location.assign("/loja");
          }
            
        })
        .catch( error => {
            console.log(error);
            //this.toastMensagem("Erro aceitar o Contrato: " + error);
        });
        }
        
      } 

      listarCurso = () =>{
        apiUrl.get("/cursos/resumo")
        .then(res => {
          for(var i=0; i < res.data.length; i++){
            if(res.data[i].id == this.state.idCurso){
              this.setState({               
                curso: JSON.parse(JSON.stringify(res.data[i])),
                qntAulasAps: JSON.parse(JSON.stringify(res.data[i].aulas.length))
              });
            }
          }
            

        })
        .catch(error => {
          console.log(error);
        })
      }

      listarAulas = async (id) =>{
        await apiUrl.get(`/usuario-curso/${id}/${this.state.idCurso}`)
        .then(res => {
          console.log(`/usuario-curso/${id}/${this.state.idCurso}`);
          console.log(res.data);
          if(res.data != null || res.data.length != 0){
            let listaTemas = [];
            let temaAnterior = null;
            let indice = -1;
          for (let i=0; i < res.data.aulas.length; i++) {
            let _aula = res.data.aulas[i];
            if ( _aula.temas !== temaAnterior ) {
              listaTemas.push( [ _aula.temas, [] ] );
              temaAnterior = _aula.temas;
              indice++;
            }
    
            if (indice >= 0)
              listaTemas[indice][1].push(_aula);
          } 
    
   
          // Obter a aula atual
          indice = 0;
          if ( res.data.usuarioAula.length > 0 ) {        
            let _data = res.data.usuarioAula.dataConclusao;
            let ultimaAula = res.data.usuarioAula[0];
            
            for (let i = 1; i < res.data.usuarioAula.length; i++) {
              if ( res.data.usuarioAula[i].dataConclusao > _data ) {
                _data = res.data.usuarioAula[i].dataConclusao;
                ultimaAula = res.data.usuarioAula[i];
              }
            }
            
            for (let i=0; i < res.data.aulas.length; i++) {
              if ( res.data.aulas[i].id === ultimaAula.idAula ) {
                indice = i+1;
                break;
              }
            }
          
          }
    
    
          if ( indice >= res.data.aulas.length )
            indice = 0;
          
          this.setState({
            idCursoAluno: res.data.id,
            nome: res.data.curso.nome,
            descricao: res.data.curso.descricao,
            temas: res.data.curso.temas,
            aulas: res.data.aulas,
            porcentagem: res.data.porcentagem,
            progresso: res.data.usuarioAula,
            loading: false,
            indiceAulaAtual: indice,
            aulaAtual: res.data.aulas[indice],
            listaTemas: listaTemas
          });
    
          }else{
            this.toastMensagem('Ainda não há aulas gravadas deste curso.');
          }
          
    
        })
        .catch(error => {
          console.log(error);         
         // window.history.go(-1);
        });
      }

      listarLive = async () =>{
        await apiUrl.get(`/video-live`)
        .then(res => {
          for(var i = 0; i < res.data.length; i++){
            if(moment(res.data[i].data).format("YYYY-MM-DD") == moment(new Date()).format("YYYY-MM-DD") && res.data[i].idCurso == this.state.idCurso){
              this.setState({
                listarLive: res.data[i],
              });  
            }
          }            
        })
        .catch(error => {
          console.log(error);
          //console.log(error);
          //window.history.go(-1);
        });
      }

      chamarProximo = e => {      
        e.preventDefault();
    
        this.concluirAula();
    
        let indice = (parseInt(this.state.indiceAulaAtual) + 1);
        
        // Concluiu o curso
        if ( indice >= this.state.aulas.length ) {
          //alert("Parabéns! Você concluiu o curso!");
          return;
        }
    
        this.setState({
          indiceAulaAtual: indice,
          aulaAtual: this.state.aulas[indice],
        });
    
      }
    
      chamarAnterior = e => {      
        e.preventDefault();
    
        let indice = this.state.indiceAulaAtual - 1;
        
        if ( indice < 0 ) {
          return;
        }
    
        this.setState({
          indiceAulaAtual: indice,
          aulaAtual: this.state.aulas[indice],
        });
        
      }

      concluirAula = async () => {
        // testa se a aula já está marcada como concluída
        if ( this.state.progresso.filter( p => p.idAula === this.state.aulaAtual.id).length > 0 )
          return;
    
        let body = {
          idUsuarioCurso: this.state.idCursoAluno,
          idUsuario: this.state.idUsuario,
          idAula: this.state.aulaAtual.id,
          idCurso: this.state.idCurso,
          dataConclusao: moment().format('YYYY-MM-DD HH:mm:ss'),
        };
    
        this.setState({
          progresso: [ ...this.state.progresso, body ],
          porcentagem: parseInt( (100 * (this.state.progresso.length + 1) ) / this.state.aulas.length),
        })
    
    
        console.log(this.state.progresso);
        await apiUrl.post(`/usuario-aula`, body)
        .then(res => {
          console.log(res.data);
        })
        .catch(error => {
          this.toastMensagem(error);
          //window.history.go(-1);
        });
      }

      chamarAulaAoVivo = () => {  
        if(document.getElementById('videoAulasAoVivo').style.display.value === undefined) {
          document.getElementById('videoAulasAoVivo').style.display = "block";
          document.getElementById('videoAulas').style.display = "none";
        }
      }

      chamarAula = (idAula) => {  
        if(document.getElementById('videoAulas').style.display.value === undefined) {
          document.getElementById('videoAulas').style.display = "block";
          document.getElementById('videoAulasAoVivo').style.display = "none";
        }
    
        let indice = 0;
    
        for (let i=0; i < this.state.aulas.length; i++) {
          if ( this.state.aulas[i].id === idAula)
            indice = i;
        }
    
        // Concluiu o curso
        // if ( indice >= this.state.aulas.length ) {
        //   alert("Parabéns! Você concluiu o curso!");
        //   return;
        // }
    
        this.setState({
          indiceAulaAtual: indice,
          aulaAtual: this.state.aulas[indice],
        });
      }
      
      lermais = e =>{
        e.preventDefault();
        
        if(document.getElementById('texto').style.display === "none") {
          document.getElementById('textoCompleto').style.display = "none";
          document.getElementById('texto').style.display = "block";
        } else {    
          document.getElementById('texto').style.display = "none";
          document.getElementById('textoCompleto').style.display = "block";
        }
      }

      componentDidMount(){
        const M = window.M;
        document.addEventListener('DOMContentLoaded', function() {
          var elems = document.querySelectorAll('.slider');
          var instances = M.Slider.init(elems, {interval: 5000});
        });

        document.addEventListener('DOMContentLoaded', function() {
          var elems = document.querySelectorAll('ul.tabs');
          var instances = M.Tabs.init(elems, {
            swipeable: true,
          });
        });
        
        document.addEventListener('DOMContentLoaded', function() {
          var elems = document.querySelectorAll('.modal');
          var instances = M.Modal.init(elems, {});
        })

        document.addEventListener('DOMContentLoaded', function() {
          var elems = document.querySelectorAll('.collapsible');
          var instances = M.Collapsible.init(elems, {});
        });

        
      }

      toastMensagem(message){
        const M = window.M;
        M.toast({html: message, classes: 'rounded'})
      }
      

      render(){
        const {descricao, loreIpsum, curso, listarLive, qntAulasAps, aulas, linkVideo, aulaAtual, nome, porcentagem, progresso, listaTemas, loading} = this.state;

        const moveFilmesEsquerda = () =>{
          let x = this.state.scrollXX + 200;
          if(x > 0){
            x = 0;
          }

          this.setState({scrollXX: x});
        }
        const moveFilmesDireita = () =>{
          let x = this.state.scrollXX - 200;
          let listW = 150*12;
          if((window.innerWidth - listW) > x){
            x = window.innerWidth - listW - 60;
          }
          this.setState({scrollXX: x})
        }

        return(
          <div className="curso">
            <Menu/>
            <section className="fundo" style={{
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundImage: 'url('+curso.urlBanner+')',
            }}>
              <div className="fundoVertical">
                <div className="fundoHorizontal"></div>
              </div>  
            </section>
            <section className="destaque">
              <div className="destaqueVertical">
                <div className="destaqueHorizontal">
                  <div className="destaqueNome">{curso.nome}</div>
                  <div className="destaqueInfo">
                    <div className="destaquePontos">{qntAulasAps} aulas</div>
                    <div className="destaqueAno">{}</div>
                    <div className="destaqueAno">{}</div>
                  </div>
                  <div className="destaqueDescricao">
                    {curso.descricao}
                  </div>
                  <div className="destaqueBotao">
                    {/*<a href="" className="botaoBranco">► Assistir</a>
                    <a href="" className="botaoPreto">+ Minha Lista</a>*/}
                  </div>
                  <div className="destaqueProf">{}</div>
                </div>
              </div>  
            </section>
            <div className="videoAulasAoVivo" id="videoAulasAoVivo">         
              <div className='content-video'>
                <iframe src={"https://www.youtube.com/embed/"+listarLive.link+"?rel=0"} title="YouTube video player" frameBorder="0" allowFullScreen=""
                    allow="accelerometer;" rel="0" showinfo="0"  className='video' float="left">  
                </iframe>   
                <div id="topVideo"></div> 
                <div id="bottomVideo"></div> 
              </div>
              <table>
                <thead>
                  <tr>
                    <td><a className='btn' href="https://discord.gg/Cj6uGqx7rP" target='_blank'>Chat da aula ao vivo</a></td>                  
                    <td><a className='btn' href="https://discord.gg/bU5sVKBCj2" target='_blank'>Comunidade no Discord</a></td>                  
                  </tr>
                </thead>     
              </table>
            </div>
            <div className="videoAulas" id="videoAulas">
            <div className="responsive-video-container col s12 m8 l9">
              <iframe src={aulaAtual.urlVideo+"?controls=0"} title="YouTube video player" frameBorder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;" allowFullScreen></iframe>
                <table >
                    <thead>
                      <tr>
                        <th colSpan="2">
                          <input type="button" value="<" onClick={this.chamarAnterior} href="#video" />  
                          <input type="button" value={porcentagem + "%"} />  
                          <input type="button" value=">" onClick={this.chamarProximo}/> 
                        </th>
                      </tr>                      
                    </thead>
                      <tbody>
                        <tr>
                          <td>{aulaAtual.nome}</td>
                          <td><input type="button" className="right" value="PDF" onClickCapture={() => window.open(aulaAtual.urlPdf, "_blank" )}/> </td>
                        </tr>
                        <tr>
                          <td colSpan="2"><p className="texto" id="texto" dangerouslySetInnerHTML={{ __html: /*nl2br(aulaAtual.texto)*/ aulaAtual.texto }}></p> </td>
                        </tr>
                      </tbody>
                </table>      
                     
            </div>
            </div>
            <div className="videoAulas">
              <iframe src={linkVideo} title="YouTube video player" frameBorder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div>
            <div className="info">            
              <ul className="collapsible">
                <li className='active'>
                  <div className="collapsible-header">Ao vivo</div>
                  <div className="collapsible-body">
                    <ul className="collection">
                      <li className="collection-item" onClickCapture={ listarLive.length == 0 ?'' :() => this.chamarAulaAoVivo() }>
                        <span className="title" >{listarLive.length == 0 ? 'Hoje não haverá aula ao vivo deste curso' : listarLive.titulo}</span>
                        <p>
                          {listarLive.length == 0 ?'Aproveite para assistir as aulas gravadas deste curso' :listarLive.descricao}
                          <br />
                          {listarLive.length == 0 ?" " :"Hoje às " + moment(listarLive.data).format("HH:mm")}
                          <br />
                        </p>
                      </li>              
                    </ul>
                  </div>
                </li>
                <li>
                  <div className="collapsible-header">Assistir Aulas Gravadas</div>
                  <div className="collapsible-body">
                    <ul className="collection">
                      { aulas.length > 0
                        ?
                          aulas.map(a =>(
                            <li className="collection-item" key={a.id} id={""+a.numero} onClickCapture={ () => this.chamarAula(a.id) }>
                              <span className="title" >{a.nome}</span>
                              <p>
                                {a.descricao}
                                <br />
                                {(a.duracao/100)} min
                                <br />
                                <a href={a.urlPdf} target="_blank" >BAIXAR PDF</a>
                              </p>
                            </li>
                          ))
                        :
                          <li className="collection-item">
                            <span className="title" >Ainda não há aulas gravadas deste curso.</span>
                            <p>
                              Estamos trabalhando nisto.
                              <br />
                            </p>
                          </li>
                    }                 
                    </ul>       
                  </div>
                </li>
              </ul>
            </div>
            <Footer />     
          </div>
      );
    }
}
export default Curso;