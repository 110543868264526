import React, { Component } from 'react';
//import { Button, Card, Row, Col, Image, CardTitle } from 'react-materialize';
import moment from 'moment';
import { Pie } from 'react-chartjs-2';

//Paginas

import apiUrl from '../../axiosApi';
import Menu from '../template/menu.component/index';
import Footer from '../template/footer.component/index'
import './index.css';

//Imagens

function nl2br(texto) {
  return texto.replace(new RegExp('\r?\n','g'), '<br />');
}

class Home extends Component{
      constructor(props){
        super(props)
        this.state = {
          scrollXX: 0,
          idCursoAluno: 0,
          idCurso: props.match.params.idCurso,
          idUsuario: localStorage.getItem('idUsuario'),
          nome: "",
          texto: "",  
          temas: "",
          porcentagem: 0,
          aulas: [],
          loading: true,
          aulaAtual: 0,
          indiceAulaAtual: 0,
          progresso: [],
          listaTemas: [],
          curso: [],
          qntAulasAps: 0,
          complemento: 0,
          contratado: false,
      }
       
        //this.getHttp();  
        //this.listarCurso();
        //this.listarAulas();
        //pageView.log('home');
        this.verificaSeJaAceitouContrato();
      }

      getHttp = () =>{
        const {id} = this.state;
  
        apiUrl.get(`/usuario/${id}/`).then( res => {
            if( res.data.dadosBancarios && res.data.dadosBancarios.length > 0 ){
              this.setState({dadosBanco: true});
            }
            if( res.data.endereco ){
              this.setState({dadosEndereco: true});
            }

            //Dados Pessoais
            this.setState({
              "id": res.data.id,
              "nome": res.data.nome,
              "cpf": res.data.cpf,
              "email": res.data.dadosCadastro.email,
              "telefone": res.data.dadosCadastro.telefone || "",
              "dtCadastro": res.data.dadosCadastro.dataCadastro,
              "dtNascimento": res.data.dadosCadastro.dataNascimento || "",
              "sexo": res.data.dadosCadastro.sexo || "",
              "taxa": "7",
              "status": "2",
              "icon": ""
            });
  
          })
          .catch( error => {
              console.log(error);
              this.toastMensagem("Erro ao trazer dados do usuário, faça login novamente. Error: " + error);
          });
      } 
      
      verificaSeJaAceitouContrato = () =>{
        apiUrl.get(`/usuario-curso/modelo/${this.state.idUsuario}`)
        .then( res => {
          if(res.data.modeloCurso.id == 3)
            this.setState({contratado: true});
        })
        .catch( error => {
            console.log(error);
            this.toastMensagem("Erro aceitar o Contrato: " + error);
        });
      } 
      
      aceiteContrato = () =>{
        apiUrl.post(`/usuario-curso/modelo/aceite`, { 
          idUsuario: this.state.idUsuario
        }).then( res => {
          this.toastMensagem('Contrato Aceito, verifique seu email');
          this.setState({contratado: true});
        })
        .catch( error => {
            console.log(error);
            this.toastMensagem("Erro aceitar o Contrato: " + error);
        });
      } 

      componentDidMount(){
        const M = window.M;
        document.addEventListener('DOMContentLoaded', function() {
          var elems = document.querySelectorAll('.slider');
          var instances = M.Slider.init(elems, {interval: 5000});
        });

        document.addEventListener('DOMContentLoaded', function() {
          var elems = document.querySelectorAll('ul.tabs');
          var instances = M.Tabs.init(elems, {
            swipeable: true,
          });
        });
        
        document.addEventListener('DOMContentLoaded', function() {
          var elems = document.querySelectorAll('.modal');
          var instances = M.Modal.init(elems, {});
        })

        document.addEventListener('DOMContentLoaded', function() {
          var elems = document.querySelectorAll('.collapsible');
          var instances = M.Collapsible.init(elems, {});
        });
      }

      toastMensagem(message){
        const M = window.M;
        M.toast({html: message, classes: 'rounded'})
      }
      
      chamarPlano = (idCompra) => {      
        //e.preventDefault();
        //this.concluirAula();
        switch(idCompra){
          case 1: 
            this.setState({complemento: 1});
            break;
          case 2:
            this.setState({contratado: false});
            this.setState({complemento: 2});
            break;
          default:
            this.setState({complemento: 2});
            break;
        }
        
        var tab = document.getElementById('tabCompra');
        tab.click();
      }

      aceitedoContrato = (idCurso) => {      
        //e.preventDefault();
        //this.concluirAula();
        var check = document.getElementById('aceite');
        if(check.checked){
          switch(idCurso){
            case 3:   
              this.aceiteContrato();
              break;
            case 4:
              this.toastMensagem('Contrato Aceito, verifique seu email');
              break;
            default:
              this.toastMensagem('nada verificado');
              break;
          }
        }else{
          this.toastMensagem("ACEITE O CONTRATO PARA CONTINUAR.");
        }
        
      }

      render(){
        const {complemento, contratado} = this.state;

        const moveFilmesEsquerda = () =>{
          let x = this.state.scrollXX + 200;
          if(x > 0){
            x = 0;
          }

          this.setState({scrollXX: x});
        }
        const moveFilmesDireita = () =>{
          let x = this.state.scrollXX - 200;
          let listW = 150*12;
          if((window.innerWidth - listW) > x){
            x = window.innerWidth - listW - 60;
          }
          this.setState({scrollXX: x})
        }

        return(
          <div className="loja">
            <Menu/>
            <section className="fundo" style={{
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundImage: 'url(https://files.investquattro.com.br/imgs/bannerLoja.jpg)',
            }}>
              <div className="fundoVertical">
                <div className="fundoHorizontal"></div>
              </div>  
            </section>
            <section className="destaque">
              <div className="destaqueVertical">
                <div className="destaqueHorizontal">
                  <div className="destaqueNome">LOJA</div>
                  <div className="destaqueInfo">
                    <div className="destaquePontos">Aceite o contrato do curso para iniciar seu curso Prime</div>
                    <div className="destaqueAno">{}</div>
                    <div className="destaqueAno">{}</div>
                  </div>
                  <div className="destaqueDescricao">
                    {}
                  </div>
                  <div className="destaqueBotao">
                  </div>
                  <div className="destaqueProf">{}</div>
                </div>
              </div>  
            </section>
            <div className="info">            
              <ul class="collapsible">
                <li>
                  <div class="collapsible-header">Plano</div>
                  <div class="collapsible-body">
                    <p>Ainda não possuimos planos</p>
                  </div>
                </li>
                <li>
                  <div class="collapsible-header">Desenvolvedor Full Stack - Pague somente após começar a trabalhar</div>
                  <div class="collapsible-body">
                      <div id="complemento" className="row section scrollspy">                        
                        <div className="contrato">
                          <a href='https://christocentric-mech.000webhostapp.com/pdf/1Contrato.pdf' target='_blank'>BAIXAR</a>
                          <iframe src='https://files.investquattro.com.br/termos-e-condicoes/contrato-desenvolvedor-fullstack.pdf'></iframe>
                        </div>  
                        {contratado 
                          ?
                            <div className='aceitar'>
                              <label>
                                <input type="checkbox" required nome="aceite" id="aceite" checked/>                       
                                <span>Li e Aceito o Contrato.</span>
                              </label>
                              <br/>
                              <a className='btn' onClickCapture={ () => this.aceitedoContrato(3)} disabled>Continuar</a>
                            </div>
                          :
                            <div className='aceitar'>
                              <label>
                                <input type="checkbox" required nome="aceite" id="aceite"/>                       
                                <span>Li e Aceito o Contrato.</span>
                              </label>
                              <br/>
                              <a className='btn' onClickCapture={ () => this.aceitedoContrato(3)} >Continuar</a>
                            </div>
                        }   
                      </div>               
                  </div>
                </li>
              </ul>
            </div>
            <Footer />     
          </div>
      );
    }
}
export default Home;