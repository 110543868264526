import React, { Component } from 'react';
//import { Button, Card, Row, Col, Image, CardTitle } from 'react-materialize';
import moment from 'moment';
import { Pie } from 'react-chartjs-2';

//Material
//Paginas
import apiUrl from '../../axiosApi';
import Menu from '../template/menu.component/index';
import Footer from '../template/footer.component/index'
import './index.css';

//Imagens
import logo from '../../assets/logoteste.png'


class Home extends Component{
      constructor(props){
        super(props)
        this.state = {
        }
      }

      componentDidMount(){
        const M = window.M;
        document.addEventListener('DOMContentLoaded', function() {
          var elems = document.querySelectorAll('.slider');
          var instances = M.Slider.init(elems, {interval: 5000});
        });
        document.addEventListener('DOMContentLoaded', function() {
          var elems = document.querySelectorAll('.carousel');
          var instances = M.Carousel.init(elems, {
            noWrap: false,
            indicators: true,
            fullWidth: true,
            duration: 300,
            shift: 0,
            padding: 50,
          });
        });
        
        document.addEventListener('DOMContentLoaded', function() {
          var elems = document.querySelectorAll('.modal');
          var instances = M.Modal.init(elems, {});
        })
      }
      toastMensagem(message){
        const M = window.M;
        M.toast({html: message, classes: 'rounded'})
      }
      

      render(){
        const {} = this.state;       
        
        return(
          <div className="index">
            <nav className="nav">
              <div className="nav-wrapper">
                <img className="responsive-img" src={logo} alt="Logo Edtech"/>
                <ul id="nav-mobile" className="right">
                  <li><a className="btn" href="/login">CONECTE-SE</a></li>
                </ul>
              </div>
            </nav>
           <section className="destaque" style={{
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundImage: 'url(https://esquemaria.com.br/wp-content/uploads/2014/05/estudante-Video-Aulas.jpg)',
              
            }}>
              <div className="destaqueVertical">
                <div className="destaqueHorizontal">
                  <div className="chamda">
                    <div className="row">
                      <div className="col s10 m6">
                        <h3 className="flow-text">Curso Master Desenvolvedor Full Stack. Faça o curso e <b>pague somente após</b> começar a trabalhar. </h3>                      
                      </div>
                    </div>
                    <div className="row">
                      <div className="col s10 m6">
                        <a className="btn-large" href='/login'>INSCREVA-SE AGORA</a>                   
                      </div>
                    </div>
                  </div>
                </div>
              </div>  
            </section>
            <div className="plano container">
              <div className="row">
              <div className="col s12 m4">
                    <div className="card">
                      <div className="card-action">
                        <span className="card-title">Prime</span>
                      </div>
                      <div className="card-content">
                        <h3>Desenvolvedor Full Stack</h3>
                        <p>
                          Acesse nosso modelo Premium e tenha acesso ao curso de Tecnologia da Quattro Edtech
                          Premium, se preparando para o setor que tem mais de 200 mil vagas abertas no Brasil. Pague
                          após conseguir um trabalho. 
                        </p>      
                      </div>
                      <div class="card-action">
                        <a className="btn-large" href='/login'>INSCREVA-SE AGORA</a>    
                      </div>
                    </div>
                </div>
                <div className="col s12 m4">
                    <div className="card">
                      <div className="card-action">
                        <span className="card-title">Free</span>
                      </div>
                      <div className="card-content">
                        <h3>Curso de Negócios</h3>
                        <p>
                          Quer montar um negócio ou melhorar o seu? Cadastre-se e acesse gratuitamente nosso
                          programa de negócios e aprenda desde a concepção da ideia até conceitos importantes para
                          fazer seu negócio dar frutos.
                        </p>
                      </div>
                      <div class="card-action">
                        <a className="btn-large" href='/login'>CADASTRE-SE</a>    
                      </div>
                    </div>
                </div>
                <div className="col s12 m4">
                    <div className="card">
                      <div className="card-action">
                        <span className="card-title">Free</span>
                      </div>
                      <div className="card-content">
                        <h3>Curso de Investimentos</h3>
                        <p>  
                          Quer fazer seu dinheiro trabalhar por você?                        
                          Curso de Investimentos
                          Educação financeira para te orientar no mercado financeiro. Cadastre-se e acesse grátis nosso
                          curso de investimentos para dar seus primeiros passos. 
                        </p>
                      </div>
                      <div class="card-action">
                        <a className="btn-large" href='/login'>CADASTRE-SE</a>    
                      </div>
                    </div>
                </div>
              </div>
            </div>
            <footer className="page-footer">
              <div className="container">
                  <img className="responsive-img" src={logo} alt="logo" /><br/>                    
                    <p className="flow-text"> 
                      © 2019 Quattro Edtech. Todos os direitos reservados.<br/>
                      contato@quattro.com
                    </p>
              </div>
            </footer>           
          </div>
      );
    }
}
export default Home;