import React, { Component } from 'react';
//import { Button, Card, Row, Col, Image, CardTitle } from 'react-materialize';
import moment from 'moment';
import { Pie } from 'react-chartjs-2';

//Material
//Paginas

import apiUrl from '../../axiosApi';
import Menu from './../template/menu.component/index';
import Footer from './../template/footer.component/index'
import './index.css';

//Imagens



class Home extends Component{
      constructor(props){
        super(props)
        this.state = {
          scrollXX: 0,
          cursos: [],
          temas: [],
          lives: [],
          idUsuario: localStorage.getItem('idUsuario'),
          apresentacao: [],
          qntAulasAps: 0,
          contratado: false,
          urlImg: "https://files.investquattro.com.br",
        } 

        this.verificaSeJaAceitouContrato()
        this.listarCursos();  
        //this.listarLives();
        this.apresentacao();
        //pageView.log('home');
      }

      apresentacao = () =>{
        var numAleatorio = 1;
        apiUrl.get("/cursos/resumo")
        .then(res => {
            numAleatorio = Math.floor(Math.random() * res.data.length );
            this.setState({ 
              apresentacao: JSON.parse(JSON.stringify(res.data[numAleatorio])),
              qntAulasAps: JSON.parse(JSON.stringify(res.data[numAleatorio].aulas.length))
            });

        })
        .catch(error => {
          console.log(error);
        })
      }

      listarCursos = () =>{
        apiUrl.get("/cursos/resumo")
        .then(res => {
            console.log(res.data);
            let temas = {};
            for ( let c of res.data ) {
              if ( temas[ c.temas ] )
                temas[ c.temas ].push( c );
              else
                temas[ c.temas ] = [ c ]; 
            }
  
            this.setState({ 
              cursos: JSON.parse(JSON.stringify(res.data)),
              temas: temas,
            });
        })
        .catch(error => {
          console.log(error);
        })
      }

      listarLives = () => {
        apiUrl.get("/video-live/")
        .then(res => {
            this.setState({ 
              lives: res.data,
            });
        })
        .catch(error => {
          console.log(error);
        })
      }

      componentDidMount(){
        const M = window.M;
        document.addEventListener('DOMContentLoaded', function() {
          var elems = document.querySelectorAll('.slider');
          var instances = M.Slider.init(elems, {interval: 5000});
        });

        document.addEventListener('DOMContentLoaded', function() {
          var elems = document.querySelectorAll('.carousel');
          var instances = M.Carousel.init(elems, {
            noWrap: false,
            indicators: true,
            fullWidth: true,
            duration: 300,
            shift: 0,
            padding: 50,
          });
        });
        
        document.addEventListener('DOMContentLoaded', function() {
          var elems = document.querySelectorAll('.modal');
          var instances = M.Modal.init(elems, {});
        })
      }

      toastMensagem(message){
        const M = window.M;
        M.toast({html: message, classes: 'rounded'})
      }
      
      verificaSeJaAceitouContrato = () =>{
        apiUrl.get(`/usuario-curso/modelo/${this.state.idUsuario}`)
        .then( res => {
          if(res.data.modeloCurso.id == 3)
            this.setState({contratado: true});
        })
        .catch( error => {
            console.log(error);
            //this.toastMensagem("Erro aceitar o Contrato: " + error);
        });
      } 

      render(){
        const {cursos, temas, contratado, apresentacao, qntAulasAps, urlImg} = this.state;

        const moveFilmesEsquerda = () =>{
          let x = this.state.scrollXX + 200;
          if(x > 0){
            x = 0;
          }

          this.setState({scrollXX: x});
        }
        const moveFilmesDireita = () =>{
          let x = this.state.scrollXX - 200;
          let listW = 150*12;
          if((window.innerWidth - listW) > x){
            x = window.innerWidth - listW - 60;
          }
          this.setState({scrollXX: x})
        }
        
        return(
          <div className="home">
            <Menu/>
           <section className="destaque" style={{
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundImage: 'url('+apresentacao.urlBanner+')',
              
            }}>
              <div className="destaqueVertical">
                <div className="destaqueHorizontal">
                  <div className="destaqueNome">{apresentacao.nome}</div>
                  <div className="destaqueInfo">
                    <div className="destaquePontos">{qntAulasAps} aulas</div>
                  </div>
                  <div className="destaqueDescricao">{apresentacao.descricao}</div>
                  <div className="destaqueBotao">
                    {apresentacao.id < 3
                      ?
                        <a href={"/curso/" + apresentacao.id} className="botaoBranco" id={apresentacao.id}>► Assistir</a>
                      :
                        contratado
                        ?
                          <a href={"/curso/" + apresentacao.id} className="botaoBranco" id={apresentacao.id}>► Assistir</a>
                        :
                        <a href="/loja" className="botaoBranco" id={apresentacao.id}>Aceitar Contrato</a>
                    }
                    
                    {/*<a href="" className="botaoPreto">+ Minha Lista</a>*/}
                  </div>
                  <div className="destaqueProf">{/*Profº Vinícius Pimenta*/}</div>
                </div>
              </div>  
            </section>
            <div className="titulo">Prime</div>
            <div className="listaCursos row">
              {cursos.map(c =>(  
                c.id > 2 ?              
                  <div className="cursos" key={c.id}>
                    {contratado
                      ?
                        <a  href={"/curso/" + c.id} id={c.id}>
                          <div className="card">
                            <div className="card-image">
                              <img  src={c.urlThumbnail} alt={c.nome}/>
                              {/*<span className="card-title">{c.nome}</span>*/}
                            </div>
                            <div className="card-content">
                              <p>
                                {c.descricao}
                                <br/>
                                {c.aulas.length} aulas
                              </p>
                            </div>
                          </div>
                        </a>
                      :
                        <a  href="/loja" id={c.id}>
                          <div className="card">
                            <div className="card-image">
                              <img  src={c.urlThumbnail}  alt={c.nome}/>
                              {/*<span className="card-title">{c.nome}</span>*/}
                            </div>
                            <div className="card-content">
                              <p>
                                {c.descricao}
                                <br/>
                                {c.aulas.length} aulas
                              </p>
                            </div>
                          </div>
                        </a>
                    }
                    
                  </div>
                  :
                  ""
              ))}              
              </div>
             {/* <div className="titulo">Cursos</div>
              <div className="listaCursos row">
              {cursos.map(c =>(  
                c.id < 3 ?              
                  <div className="cursos" key={c.id}>
                    <a  href={"/curso/" + c.id} id={c.id}>
                    <div className="card">
                      <div className="card-image">
                        <img  src="https://occ-0-1022-1380.1.nflxso.net/dnm/api/v6/X194eJsgWBDE2aQbaNdmCXGUP-Y/AAAABQkKn-bheDqrWf4mKNW88pOLB1DrWIsjtVnULzWmd35rUGi_rGgdBvI38uVoRTk06jNoUwFPH0YNpNc2wHrgABtxxVqVz33w0rBVXwOO2ILOFQ_5cWN19OQkYKib.jpg?r=d31" alt={c.nome}/>
                        <span className="card-title">{c.nome}</span>
                      </div>
                      <div className="card-content">
                        <p>
                          {c.descricao}
                          <br/>
                          {c.aulas.length} aulas
                        </p>
                      </div>
                    </div>
                    </a>
                  </div>
                  :
                  ""
              ))}              
              </div>*/}
              
              <div className="titulo">Free</div>
              <div className="listaCursos row">
              {cursos.map(c =>(  
                c.id < 3 ?              
                  <div className="cursos" key={c.id}>
                    <a  href={"/curso/" + c.id} id={c.id}>
                    <div className="card">
                      <div className="card-image">
                        <img  src={c.urlThumbnail}  alt={c.nome}/>
                        {/*<span className="card-title">{c.nome}</span>*/}
                      </div>
                      <div className="card-content">
                        <p>
                          {c.descricao}
                          <br/>
                          {c.aulas.length} aulas
                        </p>
                      </div>
                    </div>
                    </a>
                  </div>
                  :
                  ""
              ))}              
              </div>
            <Footer />     
          </div>
      );
    }
}
export default Home;