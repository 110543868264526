import React, { Component } from 'react';
import InputMask from 'react-input-mask';
//import { Button, Card, Row, Col, Image, CardTitle } from 'react-materialize';
import moment from 'moment';
import { Pie } from 'react-chartjs-2';

//Material
//Paginas
import apiUrl from '../../axiosApi';
import Menu from '../template/menu.component/index';
import Footer from '../template/footer.component/index';
import config from '../../config';
import './index.css';
import pageView from '../../utils/pageView';

//Imagens
import logo from '../../assets/logoteste.png'


class Home extends Component{
      constructor(props){
        super(props)
        this.state = {
          carregou:'0',
          mensagem:'',
          id: localStorage.getItem('idUsuario'),
          nome: '',
          senha: '',
          email: '',
          cpf: '',
          telefone: '',
          dtCadastro: '',
          dtNascimento: '',
          status: '',
        }
        this.getHttp();      

        pageView.log('perfil');
      }

      changeHandler = e => {
        this.setState({[e.target.name]: e.target.value})
      }

      getHttp = () =>{
        const {id} = this.state;
  
        apiUrl.get(`/usuario/${id}/`).then( res => {
          console.log(res.data);
          //Dados Pessoais
          this.setState({"id": res.data.id});
          this.setState({"nome": res.data.nome});
          this.setState({"cpf": res.data.cpf});
          this.setState({"email": res.data.dadosCadastro.email});
          this.setState({"telefone": res.data.dadosCadastro.telefone || ""});
          this.setState({"dtCadastro": res.data.dadosCadastro.dataCadastro});
          this.setState({"dtNascimento": res.data.dadosCadastro.dataNascimento || ""});
          this.setState({"sexo": res.data.dadosCadastro.sexo || ""});
          this.setState({"status": "2"});
          this.setState({"icon": ""});                
  
             //Carrega Dados
          this.setState({"carregou": "1"});
          this.setState({"mensagem": ""});
          this.iniciaCheckbox();
  
          })
          .catch( error => {
              console.log(error);
              this.toastMensagem("Erro ao trazer dados do usuário, faça login novamente. Error: " + error);
              this.setState({"mensagem": "Erro ao carregar os Dados Pessoais. Faça login Novamente."});
          });
      }

      iniciaCheckbox(){
        var combo = document.getElementById("status");
        this.state.status > 1 ? combo.checked = true : combo.checked = false;
      }

      atualizarDadosPessoais = async e => {
        e.preventDefault();
        this.setState({"mensagem": ""}) 
        var nome = this.titleize(this.state.nome.trim());
        const {id} = this.state;
        var tel = this.state.telefone.replace('(', '').replace(')', '').replace(' ', '').replace('-', '');
        await apiUrl.put(`/usuario/${id}`, {
          nome: nome,
          email: this.state.email.trim(),        
          senha: this.state.senha.trim(),
          dtNascimento: this.state.dtNascimento,
          cpf: this.state.cpf,
          telefone: tel.trim(),
        })
        .then(res => {
          //Resposta do Servidor
          this.setState({"mensagem":  res.data.nome + " Atualizado"});
          this.atualizarDadosLogin();
          this.toastMensagem("Dados pessoais de " + res.data.nome + " foram atualizados com sucesso!")
  
        })
        .catch(error => {
          this.setState({"mensagem": "Erro ao atualizar o Perfil"});        
          this.toastMensagem("Erro ao atualizar o Perfil.");
          console.log(error);
        })
      }

      atualizarDadosLogin = async e => {
        //e.preventDefault();
        this.setState({"mensagem": ""}) 
        
        const {id} = this.state;
  
        let body = {};
  
        if ( this.state.senha )
          body.senha = this.state.senha;
  
        if (!body.senha)
          return;
  
        await apiUrl.put(`/usuario/login/${id}`, body)
        .then(res => {
          this.setState({
            "mensagem": "Login Atualizado",
          });
        })
        .catch(error => {
          this.setState({"mensagem": "Erro ao atualizar o Login"});
        })
  
      }

      //Deixa as primeiras letras de cada palavra Maiuscula
      titleize = (text) => {
        var loweredText = text.toLowerCase();
        var words = loweredText.split(" ");
        for (var a = 0; a < words.length; a++) {
            var w = words[a];
    
            var firstLetter = w[0];
            w = firstLetter.toUpperCase() + w.slice(1);
    
            words[a] = w;
        }
        return words.join(" ");
      }

      toastMensagem(message){
        const M = window.M;
        M.toast({html: message, classes: 'rounded'})
      }
      

      render(){
        const {nome, senha, email, cpf, telefone, dtNascimento, dtCadastro, status} = this.state;       
        
        return(
          <div className="login">
            <Menu/>
           <section className="destaque" style={{
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundImage: 'url(https://files.investquattro.com.br/imgs/bannerPerfil.jpg)',
            }}>
              <div className="row" id="formularios">
              <form className="section scrollspy col s10 push-s1 m8 push-m2 l6 push-l3"
                name="ddP" id="pessoais" onSubmit={this.atualizarDadosPessoais}>
                  <blockquote className=" col s12 m12 l12">
                      <p className="flow-text">Dados de Perfil</p>
                  </blockquote>
                  <p align="center" className='cadastradoEm'>Cadastrado em {moment(dtCadastro, "YYYY-MM-DD").format("DD/MM/YYYY")}</p>
                <div className="row">
                  <div className="input-field col s10 m8 push-m2">
                    <input id="nome" type="text" className="validate" required placeholder="Nome Completo"
                      name="nome" value={nome} onChange={this.changeHandler}/>
                    <label className="active" htmlFor="nome" >Nome Completo</label>   
                  </div>
                </div>
                <div className="row">
                  <div className="input-field col s10 m8 push-m2">
                    <input id="email" type="email" className="validate" required placeholder="email@email.com"
                      name="email" value={email} onChange={this.changeHandler}/>
                    <label htmlFor="email">Email</label>
                  </div>
                </div>
                <div className="row">
                  <div className="input-field col s10 m8 push-m2">
                    <input id="senha" type="password" className="validate" placeholder="*******"
                      name="senha" value={senha} onChange={this.changeHandler}/>
                    <label htmlFor="senha">Senha</label>
                  </div>
                </div>
                <div className="row">
                  <div className="input-field col s10 m8 push-m2">
                    <InputMask  id="cpf" type="text" className="validate" mask="999.999.999-99" placeholder="CPF" 
                      name="cpf" value={cpf} disabled />
                    <label htmlFor="cpf" className='cpfLabel text-white'>CPF</label>
                  </div>
                </div>
                <div className="row">
                  <div className="input-field col s10 m8 push-m2">
                    <input id="dataNasc" type="date" className="validate" required
                      name="dtNascimento" value={dtNascimento} onChange={this.changeHandler}/>
                    <label htmlFor="dataNasc">Data de Nascimento</label>
                  </div>
                </div>
                <div className="row">
                  <div className="input-field col s10 m8 push-m2">
                    <InputMask id="tel" type="tel" mask="(99)9 9999-9999" required placeholder="Cel: (99)9 9999-9999 / Fixo Cel: (99)0 9999-9999"
                      name="telefone" value={telefone} onChange={this.changeHandler}/>
                    <label htmlFor="telefone">Telefone</label>
                  </div>
                </div>
                <div className="row">
                  <div className="input-field col s10 m8 push-m2">
                    <label>
                      <input type="checkbox" required nome="status" id="status"/>
                      <span>Aceite os <a target="_blank" href="/termosdeuso">Termos de uso</a></span>
                    </label>
                  </div>
                </div>
                <br />
                <div className="row">
                  <div className="col s12 m12 l12">
                  <button className="btn waves-effect waves-light col s10 push-s1 m10 push-m1 l10 push-l1" type="submit" name="action">ATUALIZAR</button>
                  </div>
                </div>            
              </form>
              <br />
            </div>
            
            </section>
            <Footer />         
          </div>
      );
    }
}
export default Home;